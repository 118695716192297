import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_PACKAGE_DETAILS, 
            ADD_PACKAGE_DETAILS,
            UPDATE_PACKAGE_DETAILS,
            DELETE_PACKAGE_DETAILS,
            GET_ADDONS_BY_CATEGORIES,
            ADD_PACKAGE_BOOKPERIOD_DETAILS,
            DUPLICATE_PACKAGE_DETAILS,
            GET_SURCHARGES_DETAILS,
            ADD_NEW_PACKAGE_GROUP,
            ADD_ACTIVE_TRAVELAGENT,
            GET_ACTIVE_CUSTOMER,
            DELETE_AGENT_GROUP,
            UPDATE_AGENT_GROUP,
            GET_PACKAGE_DEPOSITOR,
            GET_NON_PROMOTION,
            GET_UNREGISTERED_USERS,
            ADD_INFLUENCERS_PACKAGE,
            UPDATE_AND_DELETE_INFLUENCERS_PACKAGE,
            GET_PACKAGE_PERFORMANCE_REPORT,
            GET_PACKAGE_PERFORMANCE_PORTAL_REPORT,
            GET_PACKAGE_PERFORMANCE_REPORT_BY_PAX,
         } = URLS;
    return {
         getPackageDetails: (addOnUrl,body={}, method='get') =>
         performRequest( method ? method:'get', GET_PACKAGE_DETAILS+addOnUrl, body ),
         getAddonsBy: (body={}, method='get') =>
         performRequest( method ? method:'get', GET_ADDONS_BY_CATEGORIES, body ),
         addPackageDetails: (body={}, method='post') =>
         performRequest( method ? method:'post', ADD_PACKAGE_DETAILS, body ),
         getGroupPackageInfo: (body={}, method='get') =>
         performRequest( method ? method:'get', ADD_NEW_PACKAGE_GROUP, body ),
         getActiveAgents: (body={}, method='get') =>
         performRequest( method ? method:'get', `${ADD_ACTIVE_TRAVELAGENT}&fetchAll=true`, body ),
         getActiveCustomer: (body={}, method='get') =>
         performRequest( method ? method:'get', GET_ACTIVE_CUSTOMER, body ),
         addGroupPackage: (body={}, method='post') =>
         performRequest( method ? method:'post', ADD_NEW_PACKAGE_GROUP, body ),
         updatePackageDetails: (body={}, method='put') =>
         performRequest( method ? method:'put', UPDATE_PACKAGE_DETAILS, body ),
         addBookingPeriods: (body={}, method='post',id) =>
         performRequest( method ? method:'post', ADD_PACKAGE_BOOKPERIOD_DETAILS, body,'',id ),
         updateBookingPeriods: (body={}, method='put',id) =>
         performRequest( method ? method:'put', ADD_PACKAGE_BOOKPERIOD_DETAILS, body,'',id ),
         getBookingPeriods: (body={}, method='get',id) =>
         performRequest( method ? method:'get', ADD_PACKAGE_BOOKPERIOD_DETAILS, body,'',id ),
         updatePackageDetails: (body={}, method='put') =>
         performRequest( method ? method:'put', UPDATE_PACKAGE_DETAILS, body ),
         deletePackageDetails: (body={}, method='delete') =>
         performRequest( method ? method:'delete', DELETE_PACKAGE_DETAILS, body ),
         duplicatePackage: (body={}, method='post') =>
         performRequest( method ? method:'post', DUPLICATE_PACKAGE_DETAILS, body ),
         getSurchargesDetails: (body={}, method='get') =>
         performRequest( method ? method:'get', GET_SURCHARGES_DETAILS, body ),
         deleteAgentGroup: (body={}, method='delete') =>
         performRequest( method ? method:'delete', DELETE_AGENT_GROUP, body ),
         updateGroupPackage: (body={}, method='put') =>
         performRequest( method ? method:'put', UPDATE_AGENT_GROUP, body ),
         getPackageDeposit: (body={}, method='get',id) =>
         performRequest( method ? method:'get', GET_PACKAGE_DEPOSITOR, body,'',id ),
         getNonPromotion: (body={}, method='get') =>
         performRequest( method ? method:'get', GET_NON_PROMOTION, body ),
         getUnregisteredUser: (body={}, method='get') =>
          performRequest( method ? method:'get', GET_UNREGISTERED_USERS, body ),
         addInfluencerPackage: (body={}, method='post') =>
            performRequest( method ? method:'post', ADD_INFLUENCERS_PACKAGE, body),
         updateInfluencerPackage: (body={}, method='put',id) =>
            performRequest( method ? method:'put', UPDATE_AND_DELETE_INFLUENCERS_PACKAGE, body,'',id ),
         deleteInfluencerPackage: (body={}, method='delete') =>
            performRequest( method ? method:'delete', UPDATE_AND_DELETE_INFLUENCERS_PACKAGE, body ),
         getPackagePerformanceReport: (body={}, method='get') =>
            performRequest(method ? method:'get', GET_PACKAGE_PERFORMANCE_REPORT, body),
         getPackagePerformanceReportByPax: (body={}, method='get') =>
            performRequest(method ? method:'get', GET_PACKAGE_PERFORMANCE_REPORT_BY_PAX, body),
         getPackagePerformanceReportByPortal: (body={}, method='get') =>
            performRequest(method ? method:'get', GET_PACKAGE_PERFORMANCE_PORTAL_REPORT, body),
    }
}