export const generateAppConfig = () => {
  let exportResult = {
    appRepoLocation: "https://app-test.summerbayresort.com.my",
    backendUrl: "http://localhost:3100",
    jsReportUrl: "http://localhost:5488",
    travelAgentBackendUrl: "http://localhost:3000",
    adminRepoLocation: "http://localhost:3000",
    bookingAppRepoLocation: "http://localhost:3001",
  };
  if (process.env.REACT_APP_PRODUCTION_MODE === "TEST") {
    exportResult.appRepoLocation = "https://app-test.summerbayresort.com.my";
    exportResult.backendUrl = "https://api-test.summerbayresort.com.my";
    exportResult.jsReportUrl = "https://report-test.summerbayresort.com.my";
    exportResult.travelAgentBackendUrl =
      "https://api-business-test.summerbayresort.com.my";
    exportResult.adminRepoLocation =
      "https://admin-test.summerbayresort.com.my";
    exportResult.bookingAppRepoLocation = "https://booking-test.summerbayresort.com.my";
  }
  if (process.env.REACT_APP_PRODUCTION_MODE === "STAGE") {
    exportResult.appRepoLocation = "https://app.travoxis.com";
    exportResult.backendUrl = "https://admin-api.travoxis.com";
    exportResult.jsReportUrl = "https://report-test.summerbayresort.com.my";
    exportResult.travelAgentBackendUrl = "https://business-api.travoxis.com";
    exportResult.adminRepoLocation = "https://admin.travoxis.com";
    exportResult.bookingAppRepoLocation = "https://booking.travoxis.com";
  }
  if (process.env.REACT_APP_PRODUCTION_MODE === "PROD") {
    exportResult.appRepoLocation = "https://app.summerbayresort.com.my";
    exportResult.backendUrl = "https://api.summerbayresort.com.my";
    exportResult.jsReportUrl = "https://report.summerbayresort.com.my";
    exportResult.travelAgentBackendUrl =
      "https://api.business.summerbayresort.com.my";
    exportResult.adminRepoLocation = "https://admin.summerbayresort.com.my";
    exportResult.bookingAppRepoLocation = "https://booking.summerbayresort.com.my";
  }
  return exportResult;
};
