import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_STAFF_PERFORMANCE_DETAILS, GET_STAFF_PERFORMANCE_REPORT_DETAILs} = URLS;
    return {
        getStaffPerformanceDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_STAFF_PERFORMANCE_DETAILS, body),
        getStaffDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_STAFF_PERFORMANCE_REPORT_DETAILs.replace(':staff', body._id).replace(':year', body.year), body),
    }
}
