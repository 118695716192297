import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_ROOMS_BY_DATE,GET_ROOMNUMBER_COMBINABLE,GET_ROOM_CODE,GET_AVAILABLE_ROOM_TYPES,GET_MAINTENCE,BLOCK_ROOM,UN_BLOCK_MULTIPLE_ROOMS,BLOCK_MULTIPLE_ROOM_List, BLOCK_MULTIPLE_ROOM,UN_BLOCK_ROOM, CHANGE_ROOM, FILE_UPLOAD, GET_ROOMTYPE_DETAILS, ADD_ROOMTYPE_DETAILS, UPDATE_ROOMTYPE_DETAILS, DELETE_ROOMTYPE_DETAILS, GET_ONE_ROOMTYPE_DETAILS,UPDATE_ROOM_TYPE_PRIORITY,GET_ROOMS_BY_ROOMTYPES, GET_ROOM_AVAILABLE_BEDCOMBO, QUICKROOM_NOSHOW_ROOM, BLOCK_ROOM_TEMP } = URLS;
    return {
        getRoomsByDaily: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_ROOMS_BY_DATE+body.date, body),
        getroomCombinedType:(body={}, method='get') =>
            performRequest( method ? method:'get', GET_ROOMNUMBER_COMBINABLE, body ),    
        fileUpload: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', FILE_UPLOAD, body),
        getRoomTypeDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'post', GET_ROOMTYPE_DETAILS, body),
        getPackageRoomTypeDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'post', `${GET_ROOMTYPE_DETAILS}?fetchAll=true&status=true`, body),
        addRoomTypeDetails: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', ADD_ROOMTYPE_DETAILS, body),
        updateRoomTypeDetails: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', UPDATE_ROOMTYPE_DETAILS, body),
        deleteRoomTypeDetails: (body = {}, method = 'delete') =>
            performRequest(method ? method : 'delete', DELETE_ROOMTYPE_DETAILS, body),
        getRoomCodeDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', `/room-code-availability/${body}`),
        upadteRoomTypePriorityDetails: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', UPDATE_ROOM_TYPE_PRIORITY,body),
        getRoomByRoomTypes: (body = {}, method = 'get',url) =>
            performRequest(method ? method : 'get', GET_ROOMS_BY_ROOMTYPES,body),
        getReservationPerPeriod: (body = {}, method = 'get',url) =>
            performRequest(method ? method : 'get',url,body),
        getOccupancyPercentage: (body = {}, method = 'get',url) =>
            performRequest(method ? method : 'get',url,body),
        getRoomTypeCombos: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', GET_ROOM_AVAILABLE_BEDCOMBO, body),  
        changeRoom: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', CHANGE_ROOM.replace(":resId",body.resId), body),  
        roomBlock: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', BLOCK_ROOM.replace(":_id",body._id), body), 
        roomUnBlock: (body = {}, method = 'post', roomId, blockId) =>
            performRequest(method ? method : 'post', UN_BLOCK_ROOM.replace(":roomId",roomId).replace(":blockId",blockId), body), 
        getUnBlock:(body = {}, method = 'get',url) =>
            performRequest(method ? method : 'get',GET_MAINTENCE,body),
        getAvailableRoomTypes: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', GET_AVAILABLE_ROOM_TYPES, body), 
        multiRoomBlock: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', BLOCK_MULTIPLE_ROOM, body), 
        multiBlockRoomsList: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', BLOCK_MULTIPLE_ROOM_List, body), 
        unBlockMultipleRooms: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', UN_BLOCK_MULTIPLE_ROOMS.replace(":blockId", body.blockId), body), 
        postNoshowRoom: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', QUICKROOM_NOSHOW_ROOM, body), 
        unBlockRoom: (body = {}, method = 'delete', roomId) =>
            performRequest(method ? method : 'delete', BLOCK_ROOM_TEMP.replace(':_id', roomId), body),
        }
}




