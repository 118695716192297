import { performRequest } from "./api-handler";
import { URLS } from "../utility/urls";

export default () => {
  const {
    ADD_MAIL_CONTENT,
    GET_MAIL_CONTENT,
    UPDATE_MAIL_CONTENT,
    UPDATE_MAIL_CONTENT_STATUS,
    DELETE_MAIL_CONTENT,
  } = URLS;
  return {
    addmailcontent: (body = {}, method = "post") =>
      performRequest(method ? method : "post", ADD_MAIL_CONTENT, body),
    getmailcontent: (body = {}, method = "get") =>
      performRequest(method ? method : "get", GET_MAIL_CONTENT, body),
    updatemailcontent: (body = {}, method = "put", id) =>
      performRequest(
        method ? method : "put",
        UPDATE_MAIL_CONTENT.replace(":contentId", id),
        body
      ),
    deletemailcontent: (body = {}, method = "delete") =>
      performRequest(method ? method : "delete", DELETE_MAIL_CONTENT, body),
    updatemailcontentstatus: (body = {}, method = "put", id) =>
      performRequest(method ? method : "put", UPDATE_MAIL_CONTENT_STATUS.replace(":contentId", id), body),
  };
};
