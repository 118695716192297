import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { 
        GET_PACKAGE_ENQUIRY_DETAILS,
        UPDATE_PACKAGE_ENQUIRY_STATUS,
    } = URLS;
    return {
        getPackageEnquiryDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'post', GET_PACKAGE_ENQUIRY_DETAILS, body),
        updatePackageEnquiryStatus: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', UPDATE_PACKAGE_ENQUIRY_STATUS, body),
    }
}